
<div
	class="wrapper"
	layout="column"
	layout-align="center center"
>

	<mat-spinner
		*ngIf="!resultStatus"
		diameter="{{loadingSpinnerDiameter}}"
	></mat-spinner>

	<div
		*ngIf="resultStatus"
		[@statusFade]="resultStatus"
		(@statusFade.done)="statusAnimationDone($event)"
		layout="column"
		layout-align="center center"
	>
		<mat-icon
			*ngIf="resultStatus"
			svgIcon="{{resultStatusIcon}}"
			[color]="resultStatusIconColor"
			[style.width]="loadingSpinnerDiameter + 'px' "
			[style.height]="loadingSpinnerDiameter + 'px' "
		></mat-icon>

		<!-- <mat-icon svgIcon="done" color="accent"></mat-icon>
		<mat-icon svgIcon="done" color="warn"></mat-icon> -->

	</div>
</div>
