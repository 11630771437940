{
  "name": "webapp-namoadigital",
  "version": "22.9.4-677368c.0",
  "scripts": {
    "ng": "ng",
    "postinstall": "is-ci || husky install",
    "start:devlocal": "ng serve -c=devlocal --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "serve:devlocal": "ng serve -c=devlocal --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --port=4201",
    "start:dev": "ng serve -c=dev --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "serve:dev": "ng serve -c=dev --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --port=4201",
    "start:homolog": "ng serve -c=homolog --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "start:prod": "ng serve -c=production --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "build:prod": "ng build --configuration production",
    "build:homolog": "ng build -c=production,homolog",
    "build:dev": "ng build -c=dev",
    "build:devlocal": "ng build -c=devlocal",
    "test": "jest --no-cache",
    "test:dev": "jest --watch",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.1",
    "@angular/cdk": "^18.2.1",
    "@angular/cdk-experimental": "^18.2.1",
    "@angular/common": "^18.2.1",
    "@angular/compiler": "^18.2.1",
    "@angular/core": "^18.2.1",
    "@angular/forms": "^18.2.1",
    "@angular/google-maps": "^18.1.0",
    "@angular/localize": "^18.2.1",
    "@angular/material": "^18.2.1",
    "@angular/material-moment-adapter": "^18.2.1",
    "@angular/platform-browser": "^18.2.1",
    "@angular/platform-browser-dynamic": "^18.2.1",
    "@angular/router": "^18.2.1",
    "@ngx-formly/core": "^6.0.0",
    "@ngx-formly/material": "^6.0.0",
    "@types/lodash": "^4.14.162",
    "material-icons": "^1.12.0",
    "ng-animate": "^0.3.4",
    "ngx-webcam": "^0.4.1",
    "roboto-fontface": "^0.10.0",
    "rxjs": "^7.5.5",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular-eslint/builder": "18.1.0",
    "@angular-eslint/eslint-plugin": "18.1.0",
    "@angular-eslint/eslint-plugin-template": "18.1.0",
    "@angular-eslint/schematics": "18.1.0",
    "@angular-eslint/template-parser": "18.1.0",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.1",
    "@jsverse/transloco": "^7.4.3",
    "@jsverse/transloco-messageformat": "^7.0.1",
    "@ngx-formly/schematics": "^6.1.8",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/jest": "^29.5.12",
    "@types/md5": "^2.2.0",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "angular-fusioncharts": "^4.1.1",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.25.2",
    "eslint-plugin-jsdoc": "^39.2.9",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "fusioncharts": "^4.0.2",
    "husky": "^5.0.6",
    "is-ci": "^2.0.0",
    "jasmine-core": "^5.2.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jest": "^29.7.0",
    "jest-canvas-mock": "^2.5.2",
    "jest-preset-angular": "^14.2.2",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-mocha-reporter": "^2.2.5",
    "ngx-mask": "^18.0.0",
    "ts-md5": "^1.2.7",
    "ts-node": "~8.3.0",
    "typescript": "^5.4.5"
  }
}
