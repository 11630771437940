import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RoutesService } from '../routes/routes.service';
import { Subscription, Subject, Observable } from 'rxjs';
import {
	APIPortalAuthService,
	APIPortalAuthServicePostPayload,
} from '../api-services/portal/auth.service';
import {
	APIPortalAuthTokenService,
	APIPortalAuthTokenServicePostPayload,
} from '../api-services/portal/auth-token.service';
import { MainControllerService } from '../components/main-controller/main-controller.service';
import { I18nService } from '../i18n/i18n.service';
import { APIPortalLogoutService } from '../api-services/portal/auth-logout.service';
import { AuthLogoutService } from './auth.logout.service';
import { appRoles, AuthRolesService } from './auth-roles.service';
import { AuthTokenAPIGetResponse } from '../api-services/portal/auth-token.interface';
import { AuthServiceLoginData } from './auth.interface';
import { AuthTokenQrcodeAPIGetResponse } from '../api-services/portal/auth-token-qrcode.interface';
import { APIPortalAuthServicePOSTresCustomer } from '../api-services/portal/auth.interface';
import { TranslateLoadService } from '../i18n/translate-load.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private storage: Storage = localStorage;
	private isLoggedIn = false;
	private token: string;
	private tokenData: {
		economicGroupFilter?: number;
	} = {};
	private tokenInfo: AuthTokenAPIGetResponse;
	private customerObj: APIPortalAuthServicePOSTresCustomer;
	private logoutObserver: Subject<any> = new Subject<any>();

	loginRoutePathParams = {
		appRole: '',
		queryParams: {},
	};

	constructor(
		private router: Router,
		private routesService: RoutesService,
		private apiPortalAuthService: APIPortalAuthService,
		private apiPortalAuthTokenService: APIPortalAuthTokenService,
		private apiPortalLogoutService: APIPortalLogoutService,
		private mainControllerService: MainControllerService,
		private i18nService: I18nService,
		private authLogoutService: AuthLogoutService,
		private authRolesService: AuthRolesService,
		private translateLoadService: TranslateLoadService
	) {
		console.log(AuthService.name, environment);

		this.setTokenFromURLParameters();
		this.setStorageFromSessionWhenAvailable();
		this.setTokenFromLocalStorage();
		this.seCustomerObjFromLocalStorage();

		this.authLogoutService
			.getSubscribe()
			.subscribe(({ navigate, postToAPI, appRole }) =>
				this.logout(navigate, postToAPI, appRole)
			);
	}

	getToken = () => this.token;
	getTokenData = () => this.tokenData;
	getTokenInfo = () => this.tokenInfo;
	get customer(){
		return this.tokenInfo.customer;
	}
	getCustomerObj = () => this.customerObj;

	deleteTokenFromStorage() {
		this.storage.removeItem('auth');
	}

	private setTokenFromURLParameters() {
		const token = new URL(window.location.href).searchParams.get('token');
		if (token) {
			this.storage = sessionStorage;
			this.apiServiceGetTokenSuccess({
				session_api: token,
			});
		}
	}

	private setStorageFromSessionWhenAvailable(){

		let authData: any = sessionStorage.getItem('auth');

		if (authData) {
			authData = JSON.parse(authData);
			if (authData.token) {
				this.storage = sessionStorage;
			}
		}
	}

	private setTokenFromLocalStorage = () => {
		let localStorageAuth: any = this.storage.getItem('auth');

		if (localStorageAuth) {
			localStorageAuth = JSON.parse(localStorageAuth);
		} else {
			localStorageAuth = {};
		}

		this.token = localStorageAuth.token;
		this.tokenData = localStorageAuth.data || {};

		this.isLoggedIn = !!localStorageAuth.token;

		console.log('authServicerole', this.authRolesService.getRole());

		if (this.authRolesService.getRole() === appRoles.nformNonuser) {
			this.storage.removeItem('auth');
		}
	};

	private seCustomerObjFromLocalStorage = () => {
		let localStorageCustomerObj: any = this.storage.getItem('customerObj');

		if (localStorageCustomerObj) {
			localStorageCustomerObj = JSON.parse(localStorageCustomerObj);
		} else {
			localStorageCustomerObj = {};
		}

		this.customerObj = localStorageCustomerObj;
	};

	private setLanguageBasedOnTokenInfo() {
		if (this.tokenInfo.language_code) {
			const languageCode = this.tokenInfo.language_code
				.replace(/(..).*?$/, '$1')
				.toLowerCase();

			this.i18nService.updateLocale(languageCode, true);
		}
	}

	isLogged = () => this.isLoggedIn;

	// eslint-disable-next-line max-lines-per-function
	login(
		data: APIPortalAuthServicePostPayload,
		attrs = {}
	): Promise<AuthServiceLoginData> {
		const password = data.password;

		const loginPromise = new Promise<AuthServiceLoginData>(
			(resolve, reject) => {
				const authSubscription = this.apiPortalAuthService
					.post(data, attrs)
					.subscribe(
						(response) => {
							console.log('login()', response);

							if (Array.isArray(response.body.data)) {
								if (response.body.data.length === 1) {
									this.getTokenFromAPI(
										response.body.data[0],
										password,
										response.body.resultJSON.user_code
									)
										.then((tokenInfoData) =>
											resolve({
												authAPIPostResponse: null,
												authTokenAPIGetResponse: tokenInfoData,
											})
										)
										.catch(reject);
								} else {
									resolve({
										authAPIPostResponse: response.body,
										authTokenAPIGetResponse: null,
									});
								}
							} else {
								reject();
							}
						},

						(error) => {
							console.log('login()', error);
							reject(error);
						},

						() => authSubscription.unsubscribe()
					);
			}
		);

		return loginPromise;
	}

	// setTranslationBasedOnCustomer(customerObj: any){

	// 	// [todo] move it to a map object
	// 	const translateCodesFromAPI = {
	// 		1: 'pt',
	// 		2: 'en',
	// 		3: 'de'
	// 	};

	// 	const locale = translateCodesFromAPI[customerObj.TRANSLATE_CODE];

	// 	if(locale){
	// 		this.i18nService.updateLocale(locale, true);
	// 	}

	// }

	setCustomerObj(customerObj: any) {
		this.customerObj = customerObj;
		this.storage.setItem('customerObj', JSON.stringify(customerObj));
	}

	getTokenFromAPI(
		customerObj: any,
		password: string,
		userCode: string | number
	): Promise<AuthTokenAPIGetResponse> {
		return new Promise((resolve, reject) => {
			const apiServiceGetToken = this.apiServiceGetToken({
				P_CUSTOMER_CODE: customerObj.CUSTOMER_CODE,
				P_PASSWORD: password,
				P_TRANSLATE_CODE: customerObj.TRANSLATE_CODE,
				P_USER_CODE: userCode,
			});

			apiServiceGetToken
				.then(() => {
					// this.setTranslationBasedOnCustomer(customerObj);
					this.setCustomerObj(customerObj);
					this.apiServiceGetTokenInfo().then(resolve).catch(reject);
				})
				.catch(reject);
		});

		// return apiServiceGetToken;
	}

	private apiServiceGetTokenInfoSuccess(data: AuthTokenAPIGetResponse) {
		this.tokenInfo = data;
		this.setLanguageBasedOnTokenInfo();
	}

	apiServiceGetTokenInfo(attrs = {}): Promise<AuthTokenAPIGetResponse> {
		const tokenInfoPromise = new Promise<AuthTokenAPIGetResponse>(
			(resolve, reject) => {
				const authTokenInfoSubscription = this.apiPortalAuthTokenService
					.get(attrs)
					.subscribe(
						(response) => {
							this.apiServiceGetTokenInfoSuccess(response.body);
							this.translateLoadService
								.load()
								.then(() => resolve(response.body));
						},

						(error) => {
							reject(error);
						},

						() => {
							authTokenInfoSubscription.unsubscribe();
						}
					);
			}
		);

		return tokenInfoPromise;
	}

	private apiServiceGetTokenSuccess(responseBody: any) {
		this.isLoggedIn = true;

		const localStorageToken = {
			token: responseBody.session_api,
			data: {
				economicGroupFilter: responseBody.show_economic_group_filter,
			},
		};

		this.storage.setItem('auth', JSON.stringify(localStorageToken));

		this.setTokenFromLocalStorage();
	}

	apiServiceGetToken(
		data: APIPortalAuthTokenServicePostPayload,
		attrs = {}
	): Promise<any> {
		const loginPromise = new Promise((resolve, reject) => {
			const authTokenSubscription = this.apiPortalAuthTokenService
				.post(data, attrs)
				.subscribe(
					(response) => {
						console.log('apiServiceGetToken', response.body);

						this.apiServiceGetTokenSuccess(response.body);

						resolve(response);
					},

					(error) => {
						console.log('login()', error);
						reject(error);
					},

					() => authTokenSubscription.unsubscribe()
				);
		});

		return loginPromise;
	}

	setAuthWithTokenInfoAndCustomerOBJ(
		data: AuthTokenQrcodeAPIGetResponse
	): Promise<boolean> {
		this.setCustomerObj(data.customer);
		this.apiServiceGetTokenSuccess({
			session_api: data.token_info.session_api,
		});
		this.apiServiceGetTokenInfoSuccess(data.token_info);
		return this.translateLoadService.load();
	}

	logout(navigate = false, postToAPI = false, appRole: appRoles = null) {
		if (postToAPI) {
			const logoutSubscription: Subscription = this.apiPortalLogoutService
				.get({})
				.subscribe(
					(result) => console.log(result),
					(error) => console.log('logout()', error),
					() => logoutSubscription.unsubscribe()
				);
		}

		this.isLoggedIn = false;
		this.storage.removeItem('auth');
		this.setTokenFromLocalStorage();

		if (navigate) {
			const path = [this.routesService.getRoute('login').path];

			if (appRole) {
				path.push(appRole);
			}

			this.router.navigate(path);
		}

		this.logoutObserver.next(true);
	}

	getLogoutObserver = (): Observable<any> => this.logoutObserver;
}

export function authAppInit(
	authService: AuthService
	// translateLoadService: TranslateLoadService
) {
	return () => {
		if (authService.isLogged()) {
			console.log('user is logged in, please wait, we are checking the token!');

			return new Promise((resolve, reject) => {
				authService
					.apiServiceGetTokenInfo()
					.then(() => {
						// translateLoadService.load().then(resolve);
						resolve(true);
					})
					.catch(() => {
						authService.logout();
						resolve(null);
					});
			});
		}

		return true;
	};
}
