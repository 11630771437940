import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { HttpResponse } from '@angular/common/http';
import { ApiUrls } from 'src/api-urls/urls';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { APIPortalAuthServicePOSTres } from './auth.interface';

@Injectable({
	providedIn: 'root'
})
export class APIPortalAuthService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	post(
		data: APIPortalAuthServicePostPayload,
		attrs
	// ): Observable<T> {
	): Observable<HttpResponse<APIPortalAuthServicePOSTres>> {

		data.password = Md5.hashStr(data.password).toString().toUpperCase();

		return this.appHttpRequestService.getRequest({
			method: 'post',
			url: ApiUrls.portal.auth,
			payload: data,
			extras: attrs.extras
		});

	}

}

export interface APIPortalAuthServicePostPayload {
	password: string;
	[propName: string]: any;
}
