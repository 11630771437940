
<!-- COL HEADER -->
<ng-template #defaultColHeader let-colName="colName">
	<th class="mat-mdc-header-cell mat-header-cell-col-{{colName}}">
		<ng-container
			[ngTemplateOutlet]="
				this.attrs.hasTranslate
				? colHeaderLabelWithTranslate
				: colHeaderLabelNoTranslate
			"
			[ngTemplateOutletContext]="{colName}"
		>
		</ng-container>
	</th>
</ng-template>

<!-- COL HEADER LABEL NO TRANSLATE -->
<ng-template #colHeaderLabelNoTranslate let-colName="colName">
	<span>{{colName}}</span>
</ng-template>

<!-- COL HEADER LABEL WITH TRANSLATE -->
<ng-template #colHeaderLabelWithTranslate let-colName="colName">
	<span [translate]="translate[colName]"></span>
</ng-template>

<!-- BODY -->
<ng-template #rowWrap>
	<ng-container
		*ngFor="let item of pageData"
		[ngTemplateOutlet]="row || defaultRow"
		[ngTemplateOutletContext]="{
			item,
			colsWrap
		}"
	>
	</ng-container>
</ng-template>

<ng-template #defaultBody let-rowWrap="rowWrap">
	<tbody>
		<ng-container
			[ngTemplateOutlet]="rowWrap"
		></ng-container>
	</tbody>
</ng-template>

<!-- ROWS -->
<ng-template #defaultRow let-colsWrap="colsWrap" let-item="item">
	<tr class="mat-mdc-row">
		<ng-container
			[ngTemplateOutlet]="colsWrap"
			[ngTemplateOutletContext]="{item}"
		></ng-container>
	</tr>
</ng-template>

<ng-template #colsWrap let-item="item">
	<ng-container
		*ngFor="let col of attrs.cols"
		[ngTemplateOutlet]="getCol(col) || defaultCol"
		[ngTemplateOutletContext]="{
			translate: getTranslate(col),
			item: item,
			colName: getColHeaderName(col),
			col
		}"
	>
	</ng-container>
</ng-template>

<!-- COLS -->
<ng-template #defaultCol let-item="item" let-colName="colName" let-col="col">
	<td class="mat-mdc-cell mat-cell-col-{{colName}}">
		<div layout="row">
			<span class="card-row-label">
				<ng-container
					[ngTemplateOutlet]="
						this.attrs.hasTranslate
						? colHeaderLabelWithTranslate
						: colHeaderLabelNoTranslate
					"
					[ngTemplateOutletContext]="{colName}"
				>
				</ng-container>
			</span>
			<span text-wrap>
				{{item[col]}}
			</span>
		</div>
	</td>
</ng-template>

<div
	*ngIf="!loading && !error"
	layout="column"
	flex
>
	<table
		class="mat-mdc-table mat-table-responsive" cellSpacing="5"
	>
		<thead>
			<tr class="mat-mdc-header-row">
				<ng-container
					*ngFor="let col of attrs.cols"
					[ngTemplateOutlet]="getColHeader(col) || defaultColHeader"
					[ngTemplateOutletContext]="{
						colName: getColHeaderName(col)
					}"
				>
				</ng-container>
			</tr>
		</thead>
			<ng-container
				[ngTemplateOutlet]="body || defaultBody"
				[ngTemplateOutletContext]="{rowWrap}"
			>
			</ng-container>
	</table>
</div>

<mat-paginator
	*ngIf="!loading && !error && pageConfig.length > pageConfig.pageSize"
	[length]="pageConfig.length"
	[pageSize]="pageConfig.pageSize"
	[pageSizeOptions]="[5, 10, 20]"
	[pageIndex]="pageConfig.pageIndex"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)"
>
</mat-paginator>

<div
	*ngIf="loading"
	flex
	layout="row"
	layout-align="center center"
	layout-padding
	layout-margin
>
	<loading-spinner
		size="100"
	></loading-spinner>
</div>

<div
	*ngIf="!loading && error"
	layout="column"
	layout-padding
	layout-margin
	flex
>
	<p
		class="mat-h2"
		no-margin-bottom
	>
		{{error.friendlyMessage.message}}
	</p>
</div>
