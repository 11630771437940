/* eslint-disable max-len */
export enum APIServiceOrderPlanningGETItemKeys {

	clientCode = 'clientCode',
	clientName = 'clientName',
	clientId = 'clientId',

	contractCode = 'contractCode',
	contractDesc = 'contractDesc',
	contractOperationCode = 'contractOperationCode',
	contractLastCycles = 'contractRangeType',
	cycle = 'cycle',
	workgroupCode = 'groupCode',
	items = 'item',

	itemsSummary = 'itemTot',
	lastCycle = 'lastPreventiveCycle',
	lastCycleDate = 'lastPreventiveDate',
	lastCyleMeasure = 'lastPreventiveMeasure',
	lastMeasures = 'lastMeasures',
	localCode = 'localCode',
	nextCycle1Percent = 'progress1',
	nextCycle2Percent = 'progress2',
	nextServerOrderPlannedAttendAlert = 'planningAlert',
	nextServerOrderPlannedAttendDate = 'planningDate',
	nextServerOrderPlannedAttendWeek = 'planningWeek',
	nextServerOrderSuggestAttendAlert = 'suggestedAlert',
	nextServerOrderSuggestAttendDate = 'suggestedDate',
	nextServerOrderSuggestAttendWeek = 'suggestedWeek',
	openedTickets = 'tickets',
	productCode = 'productCode',
	productDesc = 'productDesc',
	productId = 'productId',
	regionCode = 'regionCode',
	regionDesc = 'regionDesc',
	serialActive = 'active',
	serialBrand = 'brandDesc',
	serialBrandCode = 'brandCode',
	serialClassCode = 'classCode',
	serialClassColor = 'classColor',
	serialClassId = 'classId',
	serialClassAvailable = 'classAvailable',
	serialCode = 'serialCode',
	serialExtraInfo1 = 'addInf1',
	serialExtraInfo2 = 'addInf2',
	serialExtraInfo3 = 'addInf3',
	serialId = 'serialId',
	serialMeasureAvgByDay = 'serialMeasureAvgByDay',
	serialMeasureAvgMax = 'serialMeasureAvgMax',
	serialMeasureDecimalRestriction = 'serialMeasureDecimalRestriction',
	serialMeasureSuffix = 'serialMeasureSuffix',
	serialModel = 'modelDesc',
	serialModelCode = 'modelCode',
	siteCode = 'siteCode',
	siteDesc = 'siteDesc',
	structureCode = 'structureCode',
	workGroup = 'groupDesc',
	zoneCode = 'zoneCode',
	zoneDesc = 'zoneDesc',
	telemetry = 'telemetry',
	mainUser = 'mainUser',
	mainNick = 'mainNick',
	mainName = 'mainName',
	error = 'error',

	itemsDesc = 'itemCheckDesc',
	itemsType = 'itemCheckType',
	itemsSeq = 'itemCheckSeq',
	itemsCheckMat = 'itemCheckMat',

	itemSummaryAlert = 'totAlert',
	itemSummaryExpCritical = 'totExpCritical',
	itemSummaryExp = 'totExp',
	itemSummarySum = 'tot',

	OpenedTicketsId = 'ticketId',
	// OpenedTicketsOrigin = 'originDesc',
	OpenedTicketsClassColor = 'classColor',
	OpenedTicketsClassCode = 'classCode',
	OpenedTicketsClassId = 'classId',
	OpenedTicketsInternalComments = 'internalComments',
	OpenedTicketsOpenDate = 'openDate',
	OpenedTicketsOpenUser = 'openUser',
	OpenedTicketsOpenComments = 'openComments',
	OpenedTicketsOpenPhoto = 'openPhoto',
	OpenedTicketsOperationCode = 'operationCode',
	OpenedTicketsTypeDesc = 'typeDesc',
	OpenedTicketsServiceOrders = 'serviceOrders',
	OpenedTicketsSteps = 'steps',
	OpenedTicketsKanban = 'kanban',
	OpenedTicketsKanbanStage = 'kanbanStage',
	OpenedTicketsMainUserCode = 'mainUserCode',
	OpenedTicketsMainUserNick = 'mainUserNick',

	OpenedTicketsOpenInfoSerialDesiredDate = 'desiredDate',
	OpenedTicketsOpenInfoSerialIsStopped = 'isSerialStopped',
	OpenedTicketsOpenInfoSerialStoppedDate = 'stoppedDate',

	OpenedTicketsStepOrder = 'stepOrder',
	OpenedTicketsStepOrderSeq = 'stepOrderSeq',
	OpenedTicketsStepDesc = 'stepDesc',

	OpenedTicketsServiceOrdersProcessType = 'processType',
	OpenedTicketsServiceOrdersProcessTypeChange = 'processTypeChange',
	OpenedTicketsServiceOrdersProcessTypeCode = 'processTypeCode',
	OpenedTicketsServiceOrdersProcessTypeCodeChange = 'processTypeCodeChange',
	OpenedTicketsServiceOrdersDesc = 'soDesc',

	LastMeasureValue = 'value',
	LastMeasureServiceOrders = 'serviceOrders',
	LastMeasureDiffValue = 'diffValue',

	ServiceOrderTicketId = 'ticketId',
	ServiceOrderTicketProcessType = 'processType',
	ServiceOrderTicketProcessTypeCode = 'processTypeCode',
	ServiceOrderTicketClassCode = 'classCode',
	ServiceOrderTicketClassColor = 'classColor',
	ServiceOrderId = 'soId',
	serviceOrdersDesc = 'soDesc',
	serviceOrdersPDF = 'soPDF',
	serviceOrdersMat = 'mat',

	dataWeekWeek = 'week',
	dataWeekMinDay = 'minDay',
	dataWeekMaxDay = 'maxDay',
	dataWeekMinDayView = 'minDayView',
	dataWeekMaxDayView = 'maxDayView',

	dataMonthMonth = 'month',
	dataMonthMonthView = 'monthView',
	dataMonthMinDay = 'minDay',
	dataMonthMaxDay = 'maxDay',

	matCode = 'code',
	matId = 'id',
	matDesc = 'desc',
	matUn = 'un',
	matQty = 'qty',
	matQtyPlanned = 'qtyPlanned',
	matAction = 'action',
	matOrigin = 'origin'

}

export enum APIServiceOrderPlanningGETItemsTypeValues {
	normal = 'normal',
	alert = 'alert',
	expCritical = 'expCritical',
	exp  = 'exp'
}

export enum APIServiceOrderPlanningGETItemsTelemetryBlockStatusValues {
	UNBLOCKED = 'UNBLOCKED',
	BLOCKED = 'BLOCKED',
	UNBLOCK_REQUESTED = 'UNBLOCK_REQUESTED',
	BLOCK_REQUESTED = 'BLOCK_REQUESTED'
}

export interface APIServiceOrderPlanningGETRoot {
	data: APIServiceOrderPlanningGETItem[];
	dataWeek?: APIServiceOrderPlanningGETItemDataWeek[];
	dataMonth?: APIServiceOrderPlanningGETItemDataMonth[];
	class?: APIServiceOrderPlanningGETItemClass[];
	links: APIServiceOrderPlanningGETItemExpiredLinks;
	status: boolean;
}

export interface APIServiceOrderPlanningGETItemDataWeek {
	[APIServiceOrderPlanningGETItemKeys.dataWeekWeek]: string;
	[APIServiceOrderPlanningGETItemKeys.dataWeekMinDay]: string;
	[APIServiceOrderPlanningGETItemKeys.dataWeekMaxDay]: string;
	[APIServiceOrderPlanningGETItemKeys.dataWeekMinDayView]: string;
	[APIServiceOrderPlanningGETItemKeys.dataWeekMaxDayView]: string;
}

export interface APIServiceOrderPlanningGETItemDataMonth {
	[APIServiceOrderPlanningGETItemKeys.dataMonthMonth]: string;
	[APIServiceOrderPlanningGETItemKeys.dataMonthMonthView]: string;
	[APIServiceOrderPlanningGETItemKeys.dataMonthMinDay]: string;
	[APIServiceOrderPlanningGETItemKeys.dataMonthMaxDay]: string;
}

export interface APIServiceOrderPlanningGETItem {
	[APIServiceOrderPlanningGETItemKeys.clientCode]: number;
	[APIServiceOrderPlanningGETItemKeys.contractCode]: number;
	[APIServiceOrderPlanningGETItemKeys.contractDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.contractOperationCode]: number;
	[APIServiceOrderPlanningGETItemKeys.contractLastCycles]: 'dataWeek' | 'dataMonth';
	[APIServiceOrderPlanningGETItemKeys.cycle]?: number;
	[APIServiceOrderPlanningGETItemKeys.items]?: APIServiceOrderPlanningGETItemPlannedItems[];
	[APIServiceOrderPlanningGETItemKeys.itemsSummary]?: APIServiceOrderPlanningGETItemItemsSummary;
	[APIServiceOrderPlanningGETItemKeys.lastCycle]?: string;
	[APIServiceOrderPlanningGETItemKeys.lastCycleDate]?: string;
	[APIServiceOrderPlanningGETItemKeys.lastCyleMeasure]?: number;
	[APIServiceOrderPlanningGETItemKeys.lastMeasures]?: APIServiceOrderPlanningGETItemLastMeasure[];
	[APIServiceOrderPlanningGETItemKeys.localCode]: number;
	[APIServiceOrderPlanningGETItemKeys.nextCycle1Percent]?: number;
	[APIServiceOrderPlanningGETItemKeys.nextCycle2Percent]?: number;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderPlannedAttendAlert]?: 0 | 1;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderPlannedAttendDate]?: string;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderPlannedAttendWeek]?: number;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderSuggestAttendAlert]?: 0 | 1;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderSuggestAttendDate]?: string;
	[APIServiceOrderPlanningGETItemKeys.nextServerOrderSuggestAttendWeek]?: number;
	[APIServiceOrderPlanningGETItemKeys.openedTickets]?: APIServiceOrderPlanningGETItemOpenedTickets[];
	[APIServiceOrderPlanningGETItemKeys.productCode]: number;
	[APIServiceOrderPlanningGETItemKeys.productDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.productId]: number;
	[APIServiceOrderPlanningGETItemKeys.regionCode]: number;
	[APIServiceOrderPlanningGETItemKeys.regionDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.serialActive]: 0 | 1;
	[APIServiceOrderPlanningGETItemKeys.serialBrand]: string;
	[APIServiceOrderPlanningGETItemKeys.serialBrandCode]: number;
	[APIServiceOrderPlanningGETItemKeys.serialClassCode]: number;
	[APIServiceOrderPlanningGETItemKeys.serialClassColor]: string;
	[APIServiceOrderPlanningGETItemKeys.serialClassId]: string;
	[APIServiceOrderPlanningGETItemKeys.serialClassAvailable]: 0 | 1;
	[APIServiceOrderPlanningGETItemKeys.serialCode]: number;
	[APIServiceOrderPlanningGETItemKeys.serialExtraInfo1]: string;
	[APIServiceOrderPlanningGETItemKeys.serialExtraInfo2]: string;
	[APIServiceOrderPlanningGETItemKeys.serialExtraInfo3]: string;
	[APIServiceOrderPlanningGETItemKeys.serialId]: string;
	[APIServiceOrderPlanningGETItemKeys.serialMeasureAvgByDay]: string;
	[APIServiceOrderPlanningGETItemKeys.serialMeasureAvgMax]: string;
	[APIServiceOrderPlanningGETItemKeys.serialMeasureDecimalRestriction]: number;
	[APIServiceOrderPlanningGETItemKeys.serialMeasureSuffix]: string;
	[APIServiceOrderPlanningGETItemKeys.serialModel]: string;
	[APIServiceOrderPlanningGETItemKeys.serialModelCode]: number;
	[APIServiceOrderPlanningGETItemKeys.siteCode]: number;
	[APIServiceOrderPlanningGETItemKeys.siteDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.structureCode]: number;
	[APIServiceOrderPlanningGETItemKeys.workGroup]: string;
	[APIServiceOrderPlanningGETItemKeys.workgroupCode]: number;
	[APIServiceOrderPlanningGETItemKeys.zoneCode]: number;
	[APIServiceOrderPlanningGETItemKeys.zoneDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.mainUser]: number;
	[APIServiceOrderPlanningGETItemKeys.mainNick]: string;
	[APIServiceOrderPlanningGETItemKeys.mainName]: string;
	[APIServiceOrderPlanningGETItemKeys.error]: Array<{
		title: string,
		message: string
	}>;
	telemetry?: APIServiceOrderPlanningGETItemTelemetry;
	ownerType: OwnerType;
	siteCodeOwner: number;
	siteDescOwner: string;
}

export interface APIServiceOrderPlanningGETItemOpenedTickets {
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsClassCode]: number;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsClassId]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsInternalComments]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenDate]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenUser]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenComments]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenPhoto]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOperationCode]: number;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrders]: APIServiceOrderPlanningGETItemOpenedTicketsSO[];
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsSteps]: APIServiceOrderPlanningGETItemOpenedTicketsSteps[];
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsId]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsTypeDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsClassColor]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsKanban]: 0 | 1;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsKanbanStage]: APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsMainUserCode]: number;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsMainUserNick]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenInfoSerialDesiredDate]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenInfoSerialIsStopped]: 0 | 1
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsOpenInfoSerialStoppedDate]: string;

}

export interface APIServiceOrderPlanningGETItemOpenedTicketsSteps {
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsStepOrder]: number;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsStepOrderSeq]: number;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsStepDesc]: string;
}

export interface APIServiceOrderPlanningGETItemOpenedTicketsSO {
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrdersProcessType]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrdersProcessTypeChange]: string;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrdersProcessTypeCode]: APIServiceOrderPlanningGETItemProcessTypes;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrdersProcessTypeCodeChange]: APIServiceOrderPlanningGETItemProcessTypes;
	[APIServiceOrderPlanningGETItemKeys.OpenedTicketsServiceOrdersDesc]: string;
}

export interface APIServiceOrderPlanningGETItemLastMeasure {
	[APIServiceOrderPlanningGETItemKeys.LastMeasureValue]?: string;
	[APIServiceOrderPlanningGETItemKeys.LastMeasureServiceOrders]?: APIServiceOrderPlanningGETItemServiceOrder[];
	[APIServiceOrderPlanningGETItemKeys.LastMeasureDiffValue]?: string;
}

export interface APIServiceOrderPlanningGETItemServiceOrder {
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderTicketId]: string;
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderTicketProcessType]: string;
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderTicketProcessTypeCode]: APIServiceOrderPlanningGETItemProcessTypes;
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderTicketClassColor]: string;
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderTicketClassCode]: number;
	[APIServiceOrderPlanningGETItemKeys.ServiceOrderId]: string;
	[APIServiceOrderPlanningGETItemKeys.serviceOrdersDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.serviceOrdersPDF]: string;
	[APIServiceOrderPlanningGETItemKeys.serviceOrdersMat]: APIServiceOrderPlanningGETItemServiceOrdersMat[];
}

export interface APIServiceOrderPlanningGETItemItemsSummary {
	[APIServiceOrderPlanningGETItemKeys.itemSummaryAlert]: number;
	[APIServiceOrderPlanningGETItemKeys.itemSummaryExpCritical]: number;
	[APIServiceOrderPlanningGETItemKeys.itemSummaryExp]: number;
	[APIServiceOrderPlanningGETItemKeys.itemSummarySum]: number;
}

export interface APIServiceOrderPlanningGETItemPlannedItems {
	[APIServiceOrderPlanningGETItemKeys.itemsDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.itemsType]: APIServiceOrderPlanningGETItemsTypeValues;
	[APIServiceOrderPlanningGETItemKeys.itemsSeq]: number;
	[APIServiceOrderPlanningGETItemKeys.itemsCheckMat]: APIServiceOrderPlanningGETItemPlannedItemsMat[];
}

export interface APIServiceOrderPlanningGETItemClass {
	[APIServiceOrderPlanningGETItemKeys.serialClassCode]: number;
	[APIServiceOrderPlanningGETItemKeys.serialClassId]: string;
	[APIServiceOrderPlanningGETItemKeys.serialClassColor]: string;
	[APIServiceOrderPlanningGETItemKeys.serialClassAvailable]: 0 | 1;
}

export interface APIServiceOrderPlanningGETItemTelemetry {
	serialOnOff?:  0 | 1;
	serialOnOffDate?: string;
	serialGPS?: {
		lat: number;
		long: number;
		alert: 0 | 1;
		siteDistance:  number;
		date: string;
	};
	horimeter?: APIServiceOrderPlanningTelemetryHorimeterDetails;
	blockDate?: string;
	blockStatus?: APIServiceOrderPlanningGETItemsTelemetryBlockStatusValues;
	batteryV?: number;
}

interface APIServiceOrderPlanningTelemetryHorimeterDetails {
	supplierUid: string;
	supplierDesc: string;
	horimeterValue: string;
	horimeterDate: string;
}

interface APIServiceOrderPlanningGETItemExpiredLinks {
	pageRows: number;
	totalRows: number;
	limit: number;
	currentPage: number;
	totalPages: number;
	first: string;
	previous?: any;
	next: string;
	last: string;
}

interface APIServiceOrderPlanningGETItemBaseMat {
	[APIServiceOrderPlanningGETItemKeys.matCode]: number;
	[APIServiceOrderPlanningGETItemKeys.matId]: string;
	[APIServiceOrderPlanningGETItemKeys.matDesc]: string;
	[APIServiceOrderPlanningGETItemKeys.matUn]: string;
	[APIServiceOrderPlanningGETItemKeys.matQty]: number;
	[APIServiceOrderPlanningGETItemKeys.matQtyPlanned]: number;
}

export interface APIServiceOrderPlanningGETItemServiceOrdersMat extends APIServiceOrderPlanningGETItemBaseMat {
	[APIServiceOrderPlanningGETItemKeys.matAction]: 1 | 2;
}

export interface APIServiceOrderPlanningGETItemPlannedItemsMat extends APIServiceOrderPlanningGETItemBaseMat {
	[APIServiceOrderPlanningGETItemKeys.matOrigin]: 'PLANNED' | 'OPEN';
}

export enum APIServiceOrderPlanningGETItemProcessTypes {
	PREVENTIVE = 'PREVENTIVE',
	DAMAGE = 'DAMAGE',
	CORRECTIVE = 'CORRECTIVE',
	CORRECTIVE_PLANNED = 'CORRECTIVE_PLANNED',
	INSPECTION = 'INSPECTION',
}

export enum APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages {
	NOT_CLASSIFIED = 'NOT_CLASSIFIED',
	PRIORIZATION = 'PRIORIZATION',
	PLANNING = 'PLANNING',
	RELEASE_FOR_EXECUTION = 'RELEASE_FOR_EXECUTION',
	EXECUTION = 'EXECUTION',

	// STUCK_MACHINES = 'STUCK_MACHINES'
}

export const kanbanStagesTranslates = {
	[APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages.NOT_CLASSIFIED]
	: 'Route.ServiceOrderPlanning.Components.KanbanTicketQuickaccess.LabelStageNotAnalyzed',
	[APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages.PRIORIZATION]
	: 'Route.ServiceOrderPlanning.Components.KanbanTicketQuickaccess.LabelStagePrioritization',
	[APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages.PLANNING]
	: 'Route.ServiceOrderPlanning.Components.KanbanTicketQuickaccess.LabelStagePlanning',
	[APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages.RELEASE_FOR_EXECUTION]
	: 'Route.ServiceOrderPlanning.Components.KanbanTicketQuickaccess.LabelStageReadyForExecute',
	[APIServiceOrderPlanningGETItemOpenedTicketsKanbanStages.EXECUTION]
	: 'Route.ServiceOrderPlanning.Components.KanbanTicketQuickaccess.LabelStageExecution',
};

export enum OwnerType {
	OWNER_TYPE_OWN = 'OWNER_TYPE_OWN',
	OWNER_TYPE_CLIENT = 'OWNER_TYPE_CLIENT'
}

export const ownerTypeTranslates = {
	[OwnerType.OWNER_TYPE_OWN]
	: `Route.ServiceOrderPlanning.Components.OwnerType.${OwnerType.OWNER_TYPE_OWN}`,
	[OwnerType.OWNER_TYPE_CLIENT]
	: `Route.ServiceOrderPlanning.Components.OwnerType.${OwnerType.OWNER_TYPE_CLIENT}`
}
