import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum WindowMessageActions {
	reload,
	serviceOrderPlanning_UpdateAsset
};

@Injectable({
	providedIn: 'root'
})
export class MainControllerWindowMessageListenerService {

	public readonly windowMessageReload =
		new Subject<WindowMessageData_Reload>();

	public readonly windowMessageServiceOrderPlanning_UpdateAsset =
		new Subject<WindowMessageData_ServiceOrderPlanning_UpdateAsset>();

	private observables = {
		[WindowMessageActions.reload]:
			this.windowMessageReload as Subject<WindowMessageData>,
		[WindowMessageActions.serviceOrderPlanning_UpdateAsset]:
			this.windowMessageServiceOrderPlanning_UpdateAsset as Subject<WindowMessageData>
	};

	constructor(){

		self.addEventListener('message', (event) => {

			console.log('self.addEventListener', event);

			if (environment.production && !/namoadigital.com/.test(event.origin)){
				return;
			}

			const message = event.data as WindowMessageData;

			if(WindowMessageActions[message.action] !== undefined){
				console.log('MainControllerWindowMessageListenerService', message);
				this.observables[WindowMessageActions[message.action]].next(message);
			}

		}, false);

	}

}

export interface WindowMessageData {
	action: WindowMessageActions;
	data: any;
}

export interface WindowMessageData_Reload extends WindowMessageData {
	action: WindowMessageActions.reload;
	data: null;
}

export interface WindowMessageData_ServiceOrderPlanning_UpdateAsset extends WindowMessageData {
	action: WindowMessageActions.serviceOrderPlanning_UpdateAsset;
	data: {
		productCode: number;
		serialCode: number;
	};
}

export interface WindowMessageData_Reload extends WindowMessageData {
	action: WindowMessageActions.reload;
	data: null;
}

export enum IframePortalPostMessageActions {
	serialPopup = 'serviceOrderPlanningSerialPopup',
	ticketDetails = 'serviceOrderPlanningTicketDetailsPopup',
	cancellation = 'webapp_interfaceCancellation'
}
