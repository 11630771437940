
<div
	layout="column"
	flex
	[ngClass]="{
		minimized: sideNavBehavior.minimized
	}"
>


	<div
		layout="column"
		layout-padding
		layout-align="center center"
		theme-palette-hue-bg="primary-700"
		class="companyLogo"
	>
		<customer-logo></customer-logo>
		<button
			mat-icon-button
			class="minimize-button"
			(click)="minimize()"
		>
			<mat-icon svgIcon="arrow_back"></mat-icon>
		</button>
	</div>

	<div
		layout="column"
		flex
		theme-palette-hue-bg="primary"
	>

		<mat-nav-list no-padding-top>

			<mat-list-item
				*ngIf="!currentDevice.mobile && sideNavBehavior.minimized"
				(click)="minimize()"
			>
				<mat-icon matListItemIcon>menu</mat-icon>
			</mat-list-item>

			<mat-list-item
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('home').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('home').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon matListItemIcon svgIcon="home"></mat-icon>
				<span matListItemTitle>{{getRoute('home').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeDashboardOperational"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('dashboardOperational').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('dashboardOperational').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="dashboard" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('dashboardOperational').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeDashboardManagement"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('dashboardManagement').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('dashboardManagement').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="area_chart" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('dashboardManagement').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeAssets"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('assets').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('assets').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="assets" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('assets').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeAssets"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('tickets').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('tickets').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="assignment" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('tickets').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeServiceOrderPlanning"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('serviceOrderPlanning').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('serviceOrderPlanning').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="build" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('serviceOrderPlanning').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeFieldService"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('fieldService').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('fieldService').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="gps" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('fieldService').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeTechUserPlanning"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('techUserPlanning').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('techUserPlanning').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="mechanic" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('techUserPlanning').label | transloco}}</span>
			</mat-list-item>

			<mat-list-item
				*userPermissions="eUserPermissions.routeServiceOrderEdit"
				(click)="hideSideNav()"
				[routerLink]="['/' + getRoute('serviceOrder').path]"
				[routerLinkActive]="['mat-list-single-selected-option']"
				matTooltip="{{getRoute('serviceOrder').label | transloco}}"
				matTooltipPosition="right"
				[matTooltipDisabled]="!sideNavBehavior.minimized"
			>
				<mat-icon svgIcon="car-service-inspection" matListItemIcon></mat-icon>
				<span matListItemTitle>{{getRoute('serviceOrder').label | transloco}}</span>
			</mat-list-item>

			<mat-divider></mat-divider>

		</mat-nav-list>

		<navigation-bar-extras></navigation-bar-extras>

	</div>

	<mat-list class="mat-list-username" no-padding-bottom>
		<mat-list-item>
			<mat-icon svgIcon="person" matListItemIcon></mat-icon>
			<span matListItemTitl>
				<span text-overflow-ellipsis>
					{{tokenInfo.user_name}}
				</span>
			</span>
		</mat-list-item>
	</mat-list>

	<mat-nav-list class="mat-list-logout-button" no-padding-top>
		<mat-list-item (click)="logout()">
			<mat-icon svgIcon="logout" matListItemIcon></mat-icon>
			<span matListItemTitle>{{'Components.NavigationBar.logout' | transloco}}</span>
		</mat-list-item>
	</mat-nav-list>

	<mat-list no-padding-top class="mat-list-app-version">
		<mat-divider></mat-divider>
		<mat-list-item>
			<div
				flex
				layout="row"
				layout-align="end center"
				class="mat-subheader"
			>
				<div class="mat-body-strong">v{{appVersion}}</div>
				<mat-icon class="icon-app-version" svgIcon="local_offer" matListItemIcon></mat-icon>
			</div>
		</mat-list-item>
	</mat-list>

</div>
