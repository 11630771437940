<cdk-overlay-container></cdk-overlay-container>

<mat-sidenav-container
	layout="column"
	layout-fill
	(backdropClick)="closeSideNav()"
	[ngClass]="{
		'side-nav-enabled': navigationBarEnable(),
		'side-nav-minimized': sideNavBehavior.minimized
	}"
>

  <mat-sidenav
		*ngIf="navigationBarEnable()"
		#sidenav
		[mode]="matSidenavAttrs.mode"
		[opened]="matSidenavAttrs.opened"
		theme-palette-hue-bg="primary-500"
		(keydown.escape)="sideNavEscKey()"
		disableClose
		class="mat-elevation-z4"
	>
		<navigation-bar
			layout="column"
			layout-fill
			layout-fill-y-auto
		></navigation-bar>
	</mat-sidenav>

	<mat-sidenav-content
		layout="column"
		layout-fill
		layout-fill-x-auto
	>

		<div
			layout="column"
			layout-fill
		>

			<mat-toolbar
				*ngIf="
					navigationBarEnable()
					&&
					(
						currentDevice.phone
						||
						currentDevice.tablet && currentDevice.orientationPortrait
					)
				"
				flex="none"
				color="primary"
				role="heading"
			>
				<mat-toolbar-row position-relative>

					<button
						*ngIf="!backButtonEnabled()"
						(click)="sideNavToggle()"
						mat-icon-button
					>
						<mat-icon>menu</mat-icon>
					</button>

					<button
						*ngIf="backButtonEnabled()"
						(click)="backButton()"
						mat-icon-button
					>
						<mat-icon>arrow_back</mat-icon>
					</button>

					<div
						layout="row"
						layout-align="center"
						flex
						class="logo-wrapper"
					>
						<customer-logo class="logo"></customer-logo>
						<!-- <img
							src="/assets/logo-bg-dark.svg"
							class="logo"
						/> -->
					</div>
					<mobile-toolbar-right-icons
						layout="row"
						layout-align="end"
						class="right-button-space"
					>
					</mobile-toolbar-right-icons>
				</mat-toolbar-row>
			</mat-toolbar>

			<div
				layout="column"
				flex
				overflow-auto-y
			>
				<router-outlet></router-outlet>
			</div>

			<footer-app
				*ngIf="navigationBarEnable()"
			></footer-app>

		</div>

	</mat-sidenav-content>

</mat-sidenav-container>

<!-- <navigation-bar
	*ngIf="navigationBarEnable()"
></navigation-bar> -->

<!-- <div *ngIf="isLoggedIn()">

	-- {{commonModuleTest}} --
	[<span *ngIf="commonModuleTest">commonModuleTest</span>]

</div> -->

<!-- <div
	*ngIf="navigationBarEnable()"
	layout="column"
>
	<p>main-controller works!</p>
	<p>{{ 'title' | transloco }}</p>

	<navigation-bar></navigation-bar>

	<div
		layout="row"
		layout-margin
		layout-wrap
	>

		<button
			flex="10"
			mat-raised-button
		>
			Basic
		</button>

		<button
			flex="20"
			no-margin-top
			no-margin-left
			mat-raised-button
			color="primary"
		>
			Primary
		</button>

		<button
			flex
			mat-raised-button
			color="accent"
		>
			Accent
		</button>

		<button
			flex
			mat-raised-button
			color="warn"
		>
			Warn
		</button>

		<button
			flex="noshrink"
			mat-raised-button
			disabled
		>
			Disabled all it
		</button>

		<a
			flex="grow"
			mat-raised-button
			href="https://www.google.com/"
			target="_blank"
		>
			Link
		</a>

	</div>

</div> -->
